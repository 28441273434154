import React, { Component } from "react";
import { MDBSelect } from "mdbreact";

class SelectTimeFrame extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    options: [
      {
        text: "Today",
        value: "1"
      },
      {
        text: "Past week",
        value: "2"
      },
      {
        checked: true,
        text: "All time",
        value: "3"
      }
    ]
  };

  handleChange(event) {
    // get selected time frame
    var checkedValue = null;
    for (var i=0; this.state.options[i]; i++) {
      if (this.state.options[i].checked) {
        checkedValue = this.state.options[i].value;
        break
      }
    }

    // add filter
    this.props.updateFilters("time-frame", checkedValue);
  }

  render() {
    return (
      <div className="multi-select-group-wrapper dropdown-pb-3">
        <h5>Time Frame</h5>
        <MDBSelect
          options={this.state.options}
          getValue={this.handleChange}
        />
      </div>
    );
  }
}

export default SelectTimeFrame;
