
const axios = require("axios");
// const config = require("../config.js");

export function getGeoLocation(zipcode) {
    return axios
        .get(process.env.REACT_APP_MAPBOX_BASE_URL + zipcode + '.json', {
            params: {
                access_token: process.env.REACT_APP_MAPBOX_API_PUBLIC_TOKEN,
                country:'US'
            }
        })
        .then(function (response) {
            if (response.status === 200) {
                return response.data;
            } else {
                return
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getHomePageAlerts(lat, lon) {
  const path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/" + Math.floor(lat) + "/" + Math.ceil(lon) + "/?home=1"
  return axios.get(path).then(resp => {
      return resp.data;
  })
}

export function getMapPageAlerts(lat, lon) {
  const path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/" + Math.floor(lat) + "/" + Math.ceil(lon)
  return axios.get(path).then(resp => {
      return resp.data;
  })
}
