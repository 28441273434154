import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBFormInline,
  MDBInput
} from "mdbreact";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import Chart from "../components/Chart";
import { getCDCReportsAPI, getReportByCountryAPI } from "../api/ReportAPI";
import { getCategoriesAPI } from "../api/CategoryAPI";
import PulseLoader from "react-spinners/PulseLoader";

const override =
  "display: block; margin-left: 300px;margin-top:250px; border-color: red;";

const defaultCategory = "Overdose";

class ChartsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingIndicator: true,
      timeFrameOptions: [],
      drugMapCategories: [],
      cdcCategories: [
        {
          text: defaultCategory,
          value: defaultCategory,
          checked: true
        }
      ],
      selectedTimeFrame: "",
      selectedDrugMapCategory: "",
      checked: new Set([1, 2]),
      cdc: [],
      filteredCDC: [],
      cdcLegendMax: 500,
      drugMap: [],
      drugMapStateSummary: {}
    };
  }

  componentDidMount() {
    if (this.state.drugMapCategories.length === 0) {
      getCategoriesAPI().then(results => {
        let categories = [];
        let defaultCategoryID = "";
        results.data.forEach(category => {
          let isDefault = category.name === defaultCategory;
          let categoryID = category.evtcat_id.toString();
          if (isDefault) {
            defaultCategoryID = Number(categoryID);
          }
          categories.push({
            text: category.name,
            value: categoryID,
            checked: isDefault
          });
        });
        this.setState({
          drugMapCategories: categories,
          selectedDrugMapCategory: defaultCategoryID
        });
      });
    }

    if (this.state.cdc.length === 0) {
      getCDCReportsAPI().then(reports => {
        // get CDC categories (backend is returning "Overdose" only for now)
        // let categoryOptions = [];
        // reports.meta.categories.forEach((category, i) => {
        //   let checked = category === defaultCategory;
        //   categoryOptions.push({
        //     text: category,
        //     value: category,
        //     checked: checked
        //   });
        // });

        // sort time frames (from the most recent to the oldest)
        reports.meta.time_frames.sort(function(a, b) {
          let month = a.split(" ")[0];
          let year = a.split("-")[1];
          let d1 = new Date(year + month);
          month = b.split(" ")[0];
          year = b.split("-")[1];
          let d2 = new Date(year + month);
          return d2 - d1;
        });

        let timeFrameOptions = [];
        reports.meta.time_frames.forEach((timeFrame, i) => {
          let checked = i === 0;
          timeFrameOptions.push({
            text: timeFrame,
            value: timeFrame,
            checked: checked
          });
        });

        let defaultTimeFrame = timeFrameOptions[0].text;
        let filteredCDCData = reports.data.filter(
          report =>
            report.category === defaultCategory &&
            report.time_frame === defaultTimeFrame
        );

        this.setState({
          cdc: reports.data,
          // cdcCategories: categoryOptions,
          timeFrameOptions: timeFrameOptions,
          filteredCDC: filteredCDCData,
          loadingIndicator: false,
          selectedTimeFrame: defaultTimeFrame
        });
      });
    }
  }

  _updateData = (event, field) => {
    // update CDC data
    let filteredCDCData = this.state.filteredCDC.filter(report => {
      return report[field] === event[0];
    });
    if ([this.state.filteredCDC.length, 0].includes(filteredCDCData.length)) {
      // if the filtered length is the same as before or 0,
      // then filter the original dataset
      filteredCDCData = this.state.cdc.filter(report => {
        return report[field] === event[0];
      });
    }
    return filteredCDCData;
  };

  handleCheckbox = v => () => {
    let currentCheckbox = this.state.checked;
    if (currentCheckbox.has(v)) {
      currentCheckbox.delete(v);
    } else {
      currentCheckbox.add(v);
    }
    this.setState({
      checked: currentCheckbox
    });
  };

  _getDrugMapStateSummary = data => {
    let stateSummary = {};
    data.forEach(alert => {
      stateSummary[alert.state] =
        alert.state in stateSummary ? stateSummary[alert.state] + 1 : 1;
    });
    this.setState({
      drugMapStateSummary: stateSummary
    });
  };

  _getDrugMapData = (timeFrame, category) => {
    if (timeFrame.length === 0) return;

    getReportByCountryAPI("US", timeFrame, category).then(alerts => {
      let drugMapData = alerts.data;
      this.setState({
        drugMap: drugMapData
      });

      this._getDrugMapStateSummary(drugMapData);
    });
  };

  filterByDrugMapCategory = event => {
    if (event.length > 0) {
      let selected = event[0];
      this._getDrugMapData(this.state.selectedTimeFrame, selected);
      let filteredCDCData = this._updateData(event, "category");
      this.setState({
        filteredCDC: filteredCDCData,
        selectedDrugMapCategory: selected
      });
    }
  };

  filterByTimeFrame = event => {
    if (event.length > 0) {
      let selected = event[0];
      this._getDrugMapData(selected, this.state.selectedDrugMapCategory);
      let filteredCDCData = this._updateData(event, "time_frame");
      this.setState({
        filteredCDC: filteredCDCData,
        selectedTimeFrame: selected
      });
    }
  };

  updateCDCLegendMax = num => {
    this.setState({ cdcLegendMax: num });
  };

  render() {
    let chartDisplay = null;
    if (!this.state.loadingIndicator) {
      chartDisplay = (
        <Chart
          cdcReports={this.state.filteredCDC}
          dataSource={this.state.checked}
          selectedTimeFrame={this.state.selectedTimeFrame}
          drugMapReports={this.state.drugMap}
          drugMapStateSummary={this.state.drugMapStateSummary}
          updateCDCLegendMax={this.updateCDCLegendMax}
        />
      );
    } else {
      chartDisplay = (
        <PulseLoader
          css="text-align: center;margin-top:6rem;"
          sizeUnit={"px"}
          size={30}
          color={"#29b4cc"}
          loading={this.state.loadingIndicator}
        />
      );
    }

    return (
      <div className="large-gutter">
        <Navbar />
        <div className="wrapper pt-16 pb-96">
          <MDBContainer>
            <MDBRow className="">
              <MDBCol lg="12" md="12" sm="12">
                <h2 className="pt-16 mb-32 normal-weight">
            The National Vital Statistics System’s reported drug overdose death count compared to DrugMap alerts    
	    </h2>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="3" md="3" sm="12">
                <h5>Time Frame</h5>
                <MDBSelect
                  className="dropdown-pb-3 chart-dropdown"
                  options={this.state.timeFrameOptions}
                  selected="Choose a time frame"
                  getValue={this.filterByTimeFrame}
                />
              </MDBCol>
              <MDBCol lg="3" md="3" sm="12">
                <h5>DrugMap Category</h5>
                <MDBSelect
                  className="dropdown-pb-3 chart-dropdown"
                  options={this.state.drugMapCategories}
                  selected="Choose a category"
                  getValue={this.filterByDrugMapCategory}
                />
              </MDBCol>
              <MDBCol lg="3" md="3" sm="12">
                <h5>CDC Category</h5>
                <MDBSelect
                  className="dropdown-pb-3 chart-dropdown"
                  options={this.state.cdcCategories}
                  selected="Choose a category"
                />
              </MDBCol>
              <MDBCol lg="3" md="3" sm="12">
                <h5>Data source</h5>
                <MDBFormInline className="chart-select mb-4">
                  <MDBInput
                    onClick={this.handleCheckbox(1)}
                    checked={this.state.checked.has(1) ? true : false}
                    label="DrugMap"
                    type="checkbox"
                    id="checkbox1"
                    labelClass="chart-label"
                  />
                  <MDBInput
                    onClick={this.handleCheckbox(2)}
                    checked={this.state.checked.has(2) ? true : false}
                    label="CDC"
                    type="checkbox"
                    id="checkbox2"
                    labelClass="chart-label"
                  />
                </MDBFormInline>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="12" md="12" sm="12" className="chart">
                {chartDisplay}
              </MDBCol>
              <MDBCol lg="12" md="12" sm="12" className="chart-legend">
                <div className="chart-legend-inner mb-3">
                  <span className="legend-label">DrugMap alerts</span>

                  <span className="legend-number mr-2">1</span>
                  <div className="legend-circles legend-block-wrapper">
                    <div className="legend-block small circle" />
                    <div className="legend-block medium circle" />
                    <div className="legend-block large circle" />
                  </div>
                  <span className="legend-number ml-2">10+</span>
                </div>
                <div className="chart-legend-inner">
                  <span className="legend-label">
                    Drug overdose deaths reported to the CDC
                  </span>
                  <span className="legend-number mr-2">0</span>
                  <div className="legend-block-wrapper">
                    <div className="legend-block white" />
                    <div className="legend-block yellow" />
                    <div className="legend-block orange" />
                    <div className="legend-block bright-red" />
                    <div className="legend-block red" />
                  </div>
                  <span className="legend-number ml-2">
                    {this.state.cdcLegendMax}
                  </span>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol lg="8" md="8" sm="12">
                <h5 className="mt-5 mb-4">About the data</h5>
                <p className="large">
            This data visualization shows provisional counts for drug overdose deaths based on a current flow of mortality data in the National Vital Statistics System. Reported counts may not include all deaths that occurred during a given time period.    
	    </p>
                <p className="large">
                  <a
                    className=""
                    href="https://www.cdc.gov/nchs/nvss/vsrr/drug-overdose-data.htm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="pr-2">Read more about this data</span>
                    <FontAwesomeIcon
                      icon={["far", "arrow-right"]}
                      className="arrow-icon"
                    />
                  </a>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ChartsPage;
