import React, { Component } from "react";
import Home from "./pages/Home";
import Map from "./pages/Map";
import About from "./pages/About";
import ChartsPage from "./pages/Charts";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./index.css";
import { BrowserRouter, Route } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFilter, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faExternalLinkSquareAlt, faArrowLeft, faCopyright, faBars, faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/pro-light-svg-icons";

library.add(faFilter, faMapMarkerAlt, faBars, faTwitter, faExternalLinkSquareAlt, faCopyright, faArrowLeft, faArrowRight, faArrowCircleLeft, faArrowCircleRight);
class App extends Component {
  render() {
    // localStorage.clear();
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={Home} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/about" component={About} />
          <Route exact path="/charts" component={ChartsPage} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
