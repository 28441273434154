import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { MDBSelect } from "mdbreact";

import { getAllDrugs } from '../helpers/LocalStorageHelpers'


class SelectSearchDrugs extends Component {
  constructor(props) {
    super(props);

    // get all drugs from local storage or API
    this.drugs = getAllDrugs() || [];

    this.state = {
      options: this.drugs,
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.options) {
      this.setState({options: this.drugs})
    }
  }

  handleChange(event) {
    // get selected drugs
    var selectedDrugs = [];
    if (this.state.options) {
      this.state.options.forEach(function(option) {
        if (option.checked) {
          selectedDrugs.push(option.value);
        }
      })
    }

    // add filter
    this.props.updateFilters("drugs", selectedDrugs);
  }

  render() {
    // if no drugs returned, redirect to the landing page
    // if (this.drugs.length === 0) {
    //   return(
    //     <Redirect push to="/" />
    //   );
    // }

    return (
      <div className="multi-select-group-wrapper">
        <h5>Drugs</h5>
        <MDBSelect
          className="dropdown-pb-3"
          multiple
          search
          searchId="selectDrugs"
          options={this.state.options}
          selected="Choose drugs"
          selectAll
          getValue={this.handleChange}
        />
      </div>
    );
  }
}

export default SelectSearchDrugs;
