import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Navbar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";

class PrivacyPage extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="wrapper light-gray pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12" md="8" lg="7" className="pt-md-80 pb-md-80">
                <h1 className="mb-5">Privacy Policy</h1>
                <p className="large">
                  We take the security and confidentiality of your information
                  seriously. IP addresses are routinely recorded but will also
                  remain confidential. We reserve the right to use this
                  information internally, including but not limited to research
                  purposes and quality assurance purposes.
                </p>
                <p className="large">
                  We will not sell, share, rent, or otherwise reveal this
                  information to any third party except as required by law, or
                  to address issues of noncompliance. We may share aggregate
                  data with third parties in a manner that will not identify
                  you.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        <LogoBar />
        <Footer />
      </div>
    );
  }
}

export default PrivacyPage;
