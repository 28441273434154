import { combineReducers } from 'redux'
import GeoLocationReducer from './GeoLocation-reducer'
import HomePageAlertReducer from './HomePageDrugAlertsReducer'
import MapPageAlertReducer from './MapPageDrugAlertsReducer'

const rootReducer = combineReducers({
    locationState: GeoLocationReducer,
    homepagealertstate: HomePageAlertReducer,
    mappagealertstate: MapPageAlertReducer
})

export default rootReducer
