import React, { Component } from "react";
import { MDBBtn, MDBCollapse } from "mdbreact";
import AlertListItem from "./AlertListItem.js";
import SearchLocation from "../components/SearchLocation";
import SelectSearchDrugs from "../components/SelectSearchDrugs";
import SelectCategory from "../components/SelectCategory";
import SelectTimeFrame from "../components/SelectTimeFrame";
import SelectSource from "../components/SelectSource";
import SearchKeyword from "../components/SearchKeyword";
import Map from "../components/Map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { updateAlertsHelper } from '../helpers/FilterHelpers'

class AlertList extends Component {
  constructor(props) {
    super(props);

    this.appliedFilters = props.appliedFilters;
    this.selectedLocation = props.selectedLocation;

    this.updateFilters = this.updateFilters.bind(this);
  }

  state = {
    collapseID: "",
    windowSize: "",
    showListView: true,
    filteredAlerts: null,
    alerts: this.props.alertData
  };

  handleResize = e => {
    const windowSize = window.innerWidth;
    const showListView = (windowSize >= 575 && true) || false;
    this.setState(prevState => {
      return {
        showListView
      };
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate() {
    if (!this.state.filteredAlerts) {
      // initialize if null
      this.setState({
        filteredAlerts: updateAlertsHelper(this.props.alertData, this.appliedFilters)
      });
    }

    if (this.state.alerts !== this.props.alertData) {
      this.setState({
        filteredAlerts: this.props.alertData,
        alerts: this.props.alertData
      });
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
    this.setState(prevState => ({
      clicked: !prevState.clicked
    }));
  };

  showListView(toggle) {
    this.setState({
      showListView: toggle
    });
  }

  updateFilters(filterName, filterOptions) {
    this.appliedFilters[filterName] = filterOptions;
    var alerts = updateAlertsHelper(this.props.alertData, this.appliedFilters);
    this.setState({filteredAlerts: alerts});
  }

  render() {
    var filteredAlerts = this.state.filteredAlerts || this.props.alertData;
    var className = this.state.clicked ? "active" : "";
    var mapActive = this.state.showListView === false ? "active" : "";
    var listActive = this.state.showListView === true ? "active" : "";

    return (
      <div>
        <div className="d-flex filters-toggle mb-sm-4 mb-0">
          <SearchLocation
            updateFilters={this.updateFilters}
            selectedLocation={this.selectedLocation}
            updateLocation={this.props.updateLocation}
            source="AlertList"
          />
          <div>
            <MDBBtn
              onClick={this.toggleCollapse("togglefilters")}
              className={
                "d-flex filter-toggle-button ml-2 mb-0 mr-3 " + className
              }
            >
              <FontAwesomeIcon icon="filter" className="align-self-center" />
              <span className="ml-2">Filter</span>
            </MDBBtn>
          </div>
        </div>
        <MDBCollapse id="togglefilters" isOpen={this.state.collapseID}>
          <div className="ml-32 mr-32">
            <SelectCategory updateFilters={this.updateFilters} />
            <SelectSearchDrugs updateFilters={this.updateFilters} />
            <SelectTimeFrame updateFilters={this.updateFilters} />
            <SelectSource />
            <SearchKeyword updateFilters={this.updateFilters} />
          </div>
        </MDBCollapse>
        <div className="list-container-inner">
          <div className="text-center list-map-toggle mb-2 mb-sm-0 mt-2 mt-sm-0">
            <MDBBtn
              onClick={() => this.showListView(true)}
              size="sm"
              className={"tabs " + listActive}
              rounded
            >
              List
            </MDBBtn>
            <MDBBtn
              onClick={() => this.showListView(false)}
              size="sm"
              className={"tabs " + mapActive}
              rounded
            >
              Map
            </MDBBtn>
          </div>
          <div className="mb-2 pb-sm-1 pb-0 ml-sm-32 mr-sm-32 ml-4 mr-4">
          {(filteredAlerts.data.length >= 1) && (
          <div>
            Showing {filteredAlerts.data.length} alerts
            {this.props.selectedLocation.toLowerCase() === "united states" ? " in the " : " near "}
            {this.props.selectedLocation}
          </div>
	  )}
          {(filteredAlerts.data.length < 1) && (
<div><h2>There are no reports that match your search.</h2>
		  <p className="large">Try a new location or adjust the search filters.</p></div>
	  )}
          </div>

          {this.state.showListView && (
            <div>
              {
                filteredAlerts.data.slice(0,200).map((alert, index) => {
                  // limit to the first 200 alerts for view, we can change this later
                  return <AlertListItem alertItem={alert} key={index}/>
                })
              }
            </div>
          )}
          {!this.state.showListView && (
            <div className="mobile-map-wrapper">
              <Map
                userLat={this.props.userLat}
                userLon={this.props.userLon}
                filteredAlerts={filteredAlerts}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AlertList;
