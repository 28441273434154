import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MDBBadge,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";

class AlertCarousel extends React.Component {

  render() {
    const alertsPerCarousel = 3;
    const totalCarousels = Math.ceil(
      this.props.listofAlerts.length / alertsPerCarousel
    ) || alertsPerCarousel;
    const carousels = [];
    let carousel = [];

    if(this.props.listofAlerts && Array.isArray(this.props.listofAlerts)){
      for (const [index, alert] of this.props.listofAlerts.entries()) {
        
        if(alert.state_name && alert.state_name !== 'null' && alert.state_name !== ''){
          var outputPlace = alert.place_name + ", " + alert.state_name;
        } else {
          var outputPlace = alert.place_name ;
        }

        carousel.push(
          <MDBCol md="4" className="pb-4 pb-sm-4 pb-md-3" key={index}>
            <h5 className="mb-2">{alert.pub_date}</h5>
            <h4 className="mb-2">
              <a href={alert.url}>{alert.title}</a>
            </h4>
            <p className="mb-1 pb-2 light">
              <FontAwesomeIcon icon="map-marker-alt" />
              <span className="pl-2">{ outputPlace }</span>
            </p>
            <p className="mb-0">
              {
                alert.products ?
                alert.products.map((drug, i) => {
                  return <MDBBadge className="tag-drug tag" key={i}>{drug.name}</MDBBadge>
                }) : null
              }
              {
                alert.categories ?
                alert.categories.map((category, i) => {
                  return <MDBBadge className="tag-category tag" key={i}>{category.name}</MDBBadge>
                }) : null
              }
            </p>
          </MDBCol>
        )

        if (index % alertsPerCarousel === alertsPerCarousel - 1) {
          let itemID = Math.ceil(index / alertsPerCarousel);
          carousels.push(
            <MDBCarouselItem itemId={itemID} key={itemID}>
              {carousel}
            </MDBCarouselItem>
          );
          carousel = [];
        }
      }
    }

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" className="">
            <h5 className="mb-4">Latest DrugMap Alerts</h5>
          </MDBCol>
        </MDBRow>
        <MDBCarousel
          activeItem={1}
          length={totalCarousels}
          showControls={true}
          showIndicators={true}
          interval={90000}
          multiItem
          slide
        >
          <MDBCarouselInner>
            <MDBRow>
              {carousels}
            </MDBRow>
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    );
  }
}

export default AlertCarousel;
