export const defaultFilters = {
  "time-frame": "3"
}

function timeFrameFilter(data, start, end) {
  // filter data based on the given start and end dates
  let alerts = data.filter(
    d => Date.parse(d.pub_date) >= start && Date.parse(d.pub_date) < end
  );
  return alerts
}

export function updateAlertsHelper(alerts, appliedFilters) {
  // deep copy the object first
  let filteredAlerts = JSON.parse(JSON.stringify(alerts));

  // iterate and apply filters
  for (const key in appliedFilters) {
    const value = appliedFilters[key];

    if (key === "time-frame") {
      let today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      switch (value) {
        case "1":
          // "Today"
          filteredAlerts.data = timeFrameFilter(filteredAlerts.data, today, tomorrow);
          break;
        case "2":
          // "Past week"
          let lastWeek = new Date(today);
          lastWeek.setDate(lastWeek.getDate() - 7);
          filteredAlerts.data = timeFrameFilter(filteredAlerts.data, lastWeek, tomorrow);
          break;
        default:
          // "All time"
          break;
      }
    } else if (key === "keyword") {
      // case-insensitive regex match
      const keywordRegex = new RegExp(value, 'i');
      filteredAlerts.data = filteredAlerts.data.filter(
        d => d.title.match(keywordRegex)
      );
    } else if (key === "drugs") {
      if (value.length > 0) {
        filteredAlerts.data = filteredAlerts.data.filter(
          d => {
            if (d.products) {
              let productsIDs = d.products.map((product) => {
                return product.product_id}
              );
              return value.filter(v => productsIDs.includes(Number(v))).length !== 0
            } else { return false }
          }
        );
      }
      // else: default to all alerts
    } else if (key === "categories") {
      if (value.length > 0) {
        filteredAlerts.data = filteredAlerts.data.filter(
          d => {
            if (d.categories) {
              let categoriesIDs = d.categories.map((category) => {
                return category.evtcat_id}
              );
              return value.filter(v => categoriesIDs.includes(Number(v))).length !== 0
            } else { return false }
          }
        );
      }
      // else: default to all alerts
    }
  }
  return filteredAlerts
}

export function disableEnter(event) {
  // disable form submission via the "Enter" key
  if (event.which === 13) {
    event.preventDefault();
  }
}
