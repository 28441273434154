import * as types from "../actions/action_types";
import initialState from "../InitialState";

const HomePageDrugAlerts = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HOMEPAGE_DRUG_ALERTS:
            return Object.assign({}, state, {
                homePageAlerts: action.homePageAlerts
            });
        default:
            return state
    }
};
export default HomePageDrugAlerts;
