import { getDrugsAPI } from '../api/DrugAPI';
import { getCategoriesAPI } from '../api/CategoryAPI';


export function getAllDrugs() {
  // get all drugs and save to local storage
  let localDrugs = localStorage.getItem("drugs");
  if (localDrugs) {
    return JSON.parse(localDrugs)
  } else {
    getDrugsAPI().then((drugs) => {
      const drugOptions = [];
      if (drugs && drugs.data && Array.isArray(drugs.data)) {
        drugs.data.forEach(function(drug) {
          drugOptions.push({
            "text": drug.name,
            "value": drug.product_id.toString(),
            // ---> Following Checked tag was causing an issue with multiple display on FE <-----
            // "checked": true
          })
        })
      }
      localStorage.setItem("drugs", JSON.stringify(drugOptions));
      return drugOptions
    })
  }
}

export function getAllCategories() {
  // get all categories and save to local storage
  let localCategories = localStorage.getItem("categories");
  if (localCategories) {
    return JSON.parse(localCategories)
  } else {
    getCategoriesAPI().then((categories) => {
      const categoryOptions = [];
      if (categories && categories.data && Array.isArray(categories.data)) {
        categories.data.forEach(function(category) {
          categoryOptions.push({
            "text": category.name,
            "value": category.evtcat_id.toString(),
            // "checked": true
          })
        })
      }
      localStorage.setItem("categories", JSON.stringify(categoryOptions));
      return categoryOptions
    })
  }
}
