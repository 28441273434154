import React, { Component } from "react";
import ReactMapGL, { Source, Layer, Marker, Popup } from "react-map-gl";

import { dataLayer, updatePercentiles } from "../helpers/ChartHelpers";
import USCoords from "../assets/us-states-coordinates.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_PUBLIC_TOKEN;
// const mapStyle = "mapbox://styles/bchidha2019/ck0mp3cry9c491dl7b6d9o09f";
const mapStyle = process.env.REACT_APP_MAPBOX_MAPSTYLE;

class Chart extends Component {
  constructor(props) {
    super(props);

    // cdcIndicator: 1 - predicted; 2 - reported
    this.cdcIndicator = 2;
    this.state = {
      viewport: {
        width: "100%",
        height: "100%",
        latitude: 40,
        longitude: -100,
        zoom: 3.5
      },
      data: [],
      stateInfo: null,
      selectedTimeFrame: "",
      showPopup: false,
      clickedLngLat: [-100, 40]
    };
    this.mapGL = React.createRef();
    this.createMarkers = this.createMarkers.bind(this);
  }
  /*
  componentDidMount() {
    this.setState({
      data: USCoords
    });
  }
*/

  componentDidMount() {
    this.setState(prevState => ({
      data: USCoords,
      viewport: {
        // object that we want to update
        width: "100%",
        height: "100%",
        latitude: this.props.userLat,
        longitude: this.props.userLon,
        zoom: this.zoom // update the value of specific key
      }
    }));
    window.addEventListener("resize", this._resize);
    this._resize();
  }

  componentDidUpdate() {
    if (
      this.props.cdcReports.length > 0 &&
      this.props.selectedTimeFrame !== this.state.selectedTimeFrame
    ) {
      let updatedData = updatePercentiles(
        USCoords,
        this.props.cdcReports,
        this.cdcIndicator
      );
      this.setState({
        data: updatedData,
        selectedTimeFrame: this.props.selectedTimeFrame
      });
      this.props.updateCDCLegendMax(updatedData.max);
    }
  }

  _onViewportChange(viewport) {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  }

  _resize = () => {
    this._onViewportChange({
      width: "100%",
      height: "100%"
    });
  };

  /* _onHover = event => {
    const {
      features,
      srcEvent: {offsetX, offsetY}
    } = event;
    const stateInfo = features && features.find(f => f.layer.id === 'data');
    this.setState({
      stateInfo,
      x: offsetX,
      y: offsetY
    });
  };
*/
  _onClick = event => {
    const {
      features,
      srcEvent: { offsetX, offsetY }
    } = event;
    const stateInfo = features && features.find(f => f.layer.id === "data");
    this.setState({
      // showPopup: this.state.showPopup ? false : true,
      showPopup: true,
      stateInfo,
      x: offsetX,
      y: offsetY,
      clickedLngLat: event.lngLat
    });
  };

  _organizeMarkerData(data) {
    let geoData = {};
    let lat = 0;
    let lon = 0;
    let key = "";

    for (let i in data) {
      let d = data[i];
      lat = parseFloat(d.lat).toFixed(2);
      lon = parseFloat(d.lon).toFixed(2);
      key = lat + "," + lon;
      if (geoData[key]) {
        geoData[key].push(d);
      } else {
        geoData[key] = [d];
      }
    }
    return geoData;
  }

  createMarkers() {
    if (!this.props.drugMapReports) return;

    let data = this._organizeMarkerData(this.props.drugMapReports);

    let markers = [];
    let i = 0;
    let alerts = [];

    for (let key in data) {
      alerts = data[key];
      let totalAlerts = alerts.length;
      // let iconSize = (totalAlerts) < 10 ? "small" : "medium";
      totalAlerts = totalAlerts === 1 ? "" : totalAlerts;
      let iconSize = "";
      // let iconSize = (totalAlerts) < 2 ? "" : "small";
      if (totalAlerts < 2) {
        iconSize = "";
      }
      if (totalAlerts >= 2 && totalAlerts < 10) {
        iconSize = "small";
      }
      if (totalAlerts >= 10 && totalAlerts < 20) {
        iconSize = "medium";
      }
      if (totalAlerts >= 20) {
        iconSize = "large";
      }

      let latAndLon = key.split(",");
      let lat = parseFloat(latAndLon[0]);
      let lon = parseFloat(latAndLon[1]);

      markers.push(
        <Marker
          latitude={lat}
          longitude={lon}
          offsetLeft={-20}
          offsetTop={-10}
          key={i}
        >
          <div
            id={`marker-${i}`}
            className={`chart-icon ${iconSize}`}
            data-mark={i}
          >
          </div>
        </Marker>
      );
      i++;
    }

    return markers;
  }

  render() {
    const showPopup = this.state.showPopup;

    // Data source: 1 - DrugMap; 2 - CDC
    let dataSource = this.props.dataSource;

    // example: http://visgl.github.io/react-map-gl/examples/geojson
    return (
      <ReactMapGL
        onClick={this._onClick}
        mapStyle={mapStyle}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        {...this.state.viewport}
        onViewportChange={viewport => this._onViewportChange(viewport)}
        scrollZoom={true}
        ref={this.mapGL}
        //onHover={this._onHover}
      >
        {dataSource.has(1) ? this.createMarkers() : null}
        <Source type="geojson" data={this.state.data}>
          {dataSource.has(2) ? <Layer {...dataLayer} /> : null}
        </Source>
        {showPopup && this.state.stateInfo && (
          <Popup
            latitude={this.state.clickedLngLat[1]}
            longitude={this.state.clickedLngLat[0]}
            closeButton={true}
            closeOnClick={true}
            onClose={() => this.setState({ showPopup: false })}
            tipSize={12}
            captureScroll={true}
            className="chart-popup"
            anchor="bottom"
            dynamicPosition={true}
          >
            <div className="chart-popup-content">
              {this.state.stateInfo ? (
                <label className="location">
                  <FontAwesomeIcon icon="map-marker-alt" />
                  <span className="pl-2">
                    {this.state.stateInfo.properties.name}
                  </span>
                </label>
              ) : null}
              <label>
                <span className="circle-outline cdc">
                  {this.state.stateInfo.properties.reported}
                </span>
                CDC drug overdose deaths
              </label>
              {this.props.drugMapStateSummary[
                this.state.stateInfo.properties.name
              ] ? (
                <label>
                  <span className="circle-outline drugmap">
                    {
                      this.props.drugMapStateSummary[
                        this.state.stateInfo.properties.name
                      ]
                    }
                  </span>
                </label>
              ) : (
                <span className="circle-outline drugmap">0</span>
              )}
              <label>DrugMap overdose alerts</label>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    );
  }
}

export default Chart;
