
import React from "react";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import "typeface-cabin";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./assets/scss/mdb.scss";
import "./index.css";
import App from "./App";
import configureStore from './configureStore'

import registerServiceWorker from './registerServiceWorker';

require('dotenv').config()


const store = configureStore({});

const Routing = () => (
    <Provider store={store}>
        <Router>
            <App />
            {/* <AppWithAuth /> */}
        </Router>
    </Provider>
)

render(<Routing />, document.getElementById('root'))

registerServiceWorker();
