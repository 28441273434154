import * as types from "../actions/action_types";
import initialState from "../InitialState";

const MapPageDrugAlerts = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_MAPPAGE_DRUG_ALERTS:
            return Object.assign({}, state, {
                mapPageAlerts: action.mapPageAlerts
            });
        default:
            return state
    }
};
export default MapPageDrugAlerts;
