import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getReportsMetaDataAPI } from "../api/ReportAPI";
import { dateReformatter } from "../helpers/Utils";

class MapFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastUpdated: ""
    }
  }

  componentDidMount() {
    getReportsMetaDataAPI().then((data) => {
      this.setState({
        lastUpdated: dateReformatter(data.last_update)
      });
    });
  }

  render() {
    return (
      <MDBFooter color="" className="map-footer pb-2 pt-2 mt-0">
        <MDBContainer fluid>
          <MDBRow className="">
            <MDBCol size="12" sm="6">
              <p className="mb-0">
                <FontAwesomeIcon icon={["far", "copyright"]} />
                <span className="pl-2">
                  {new Date().getFullYear()}{" "}
                  <a href="http://www.childrenshospital.org" rel="noopener noreferrer" target="_blank">
                    Boston Children's Hospital
                  </a>
                </span>
              </p>
            </MDBCol>
            <MDBCol className="small" size="12" sm="6">
              <h5 className="float-left float-sm-right mb-0">Last Updated: {this.state.lastUpdated}</h5>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBFooter>
    );
  }
};

export default MapFooter;
