import React from "react";
import { withRouter } from "react-router-dom";
// import PropTypes from "prop-types";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput
} from "mdbreact";
import Navbar from "../components/NavBar";
import AlertCarousel from "../components/AlertCarousel";
import Footer from "../components/Footer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import bchlogo from "../assets/img/svg/logos/bch.svg";
import hmslogo from "../assets/img/svg/logos/hms.svg";
import hmlogo from "../assets/img/svg/logos/hm.svg";
import * as HomePageAlerts from "../actions/GeoLocation-action";
import { getAllDrugs, getAllCategories } from "../helpers/LocalStorageHelpers";
import { dateReformatter } from "../helpers/Utils";

import PulseLoader from "react-spinners/PulseLoader";

// import MapboxAutocomplete from "react-mapbox-autocomplete";

// const UserLocation = require('../components/UserCurrentLocation');

const override = "display: block; margin-left: 150px; border-color: red;";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homePageAlerts: "",
      loadingIndicator: true
    };
    localStorage.clear();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getMyLocation = this.getMyLocation.bind(this);
    this._suggestionSelect = this._suggestionSelect.bind(this);
  }

  componentDidMount() {
    this.getMyLocation()
      .then(() => {
        var currentGeoLat = localStorage.getItem("CurrentUserLat");
        var currentGeoLon = localStorage.getItem("CurrentUserLon");
        this.props.actions
          .getHomePageDrugAlerts(currentGeoLat, currentGeoLon)
          .then(locationAlerts => {
            if (locationAlerts.data && Array.isArray(locationAlerts.data)) {
              locationAlerts.data.forEach((alert, i) => {
                alert.pub_date = dateReformatter(alert.pub_date);
              });
            }
            this.setState(prevState => ({
              homePageAlerts: locationAlerts,
              loadingIndicator: false
            }));
          });
      })
      .catch(
        this.props.actions.getHomePageDrugAlerts(0, 0).then(locationAlerts => {
          if (locationAlerts.data && Array.isArray(locationAlerts.data)) {
            locationAlerts.data.forEach((alert, i) => {
              alert.pub_date = dateReformatter(alert.pub_date);
            });
          }
          this.setState(prevState => ({
            homePageAlerts: locationAlerts,
            loadingIndicator: false
          }));
        })
      );
    // get drugs and categories from the API before loading the Map page
    getAllDrugs();
    getAllCategories();
  }

  getMyLocation() {
    return new Promise(function(resolve, reject) {
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        location.getCurrentPosition(
          position => {
            localStorage.setItem("CurrentUserLat", position.coords.latitude);
            localStorage.setItem("CurrentUserLon", position.coords.longitude);
            resolve(true);
          },
          error => {
            // default to the US view
            console.log("Error, Currently setting lat/lon to 36.8465/-76.2916");
            localStorage.setItem("CurrentUserLat", 36.8465);
            localStorage.setItem("CurrentUserLon", -76.2916);
            reject(false);
          }
        );
      }
    });
  }

  _suggestionSelect(result, lat, lng, text) {
    // console.log(result, lat, lng, text);
    localStorage.setItem("userSearchLocation", result);
  }

  handleSubmit(event) {
    event.preventDefault();

    // enable submit without selecting anything from the autocomplete suggestions list
    var submittedLocation = event.target.querySelector("input").value;

    // enable submit without an input
    if (!submittedLocation) {
      submittedLocation = "United States";
    }
    localStorage.setItem("userSearchLocation", submittedLocation);

    this.props.history.push("/map");
  }

  render() {
    // console.log("Props inside Home-----> ", this.props.homePageAlerts.data)

    return (
      <div>
        <Navbar />
        <div className="d-flex align-items-center banner-bg">
          <MDBContainer>
            <MDBRow className="">
              <MDBCol md="12" lg="8">
                <MDBCard>
                  <MDBCardBody className="pt-80 pb-56 pl-md-3 pr-md-3 pl-sm-3 pr-sm-3">
                    <MDBCol lg="10" md="12" sm="12" className="offset-lg-1">
                      <h1 className="mb-3">
                        The opioid epidemic is a public health crisis.
                      </h1>
                      <h2 className="mb-0">
                        How is it impacting your community?
                      </h2>
                      <form onSubmit={this.handleSubmit}>
                        <div className="d-flex flex-md-row flex-column">
                          <div className="flex-grow-1 mr-md-3">
                            <MDBInput
                              outline
                              className="rounded"
                              size="lg"
                              label="Enter a location"
                              name="location"
                              onChange={this.onInputChange}
                              onBlur={this.onInputBlur}
                            />
                          </div>

                          <div className="align-self-md-center align-self-sm-start">
                            <MDBBtn
                              color="primary"
                              type="submit"
                              rounded
                              size="lg"
                            >
                              See drug activity
                            </MDBBtn>
                          </div>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper">
          <MDBContainer>
            <MDBRow className="text-center pt-4 pb-4">
              <MDBCol md="4" sm="12">
                <img src={bchlogo} className="logo-list" alt="logo" />
              </MDBCol>
              <MDBCol className="mt-md-0 mb-md-0 mt-3 mb-3" md="4" sm="12">
                <img src={hmlogo} className="logo-list hm" alt="logo" />
              </MDBCol>
              <MDBCol md="4" sm="12">
                <img src={hmslogo} className="logo-list" alt="logo" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper dark-white-tint pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="5" className="pt-md-80 pb-md-80">
                <h5 className="mb-4">About DrugMap</h5>
                <h2 className="mb-3">
                  Our goal is to bring awareness to the opioid epidemic and to
                  better understand community needs to generate public and
                  political action.
                </h2>
                <p className="large">
                  DrugMap is a real-time surveillance platform that identifies
                  drug-related activity in the United States. Geographic and
                  temporal insights are provided to identify the landscape and
                  scope of the opioid epidemic.
                </p>
                <MDBBtn rounded outline href="/about" color="primary">
                  Learn more
                </MDBBtn>
              </MDBCol>
              <MDBCol md="6" className="offset-md-1 about-bg" />
            </MDBRow>
          </MDBContainer>
        </div>

        <div className="wrapper pt-96 pb-96">
          <PulseLoader
            css={override}
            sizeUnit={"px"}
            size={30}
            color={"#29b4cc"}
            loading={this.state.loadingIndicator}
          />
          <AlertCarousel listofAlerts={this.props.homePageAlerts.data} />
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homePageAlerts: state.homepagealertstate.homePageAlerts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(HomePageAlerts, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));
