export function dateReformatter(d) {
  d = new Date(d)
  const formatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: 'numeric' })
  const [{ value: month },,{ value: day },,{ value: year }] = formatter.formatToParts(d)
  return `${month} ${day}, ${year}`
}

// sort array ascending
const asc = arr => arr.sort((a, b) => a - b);

export function quantile(arr, q) {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};
