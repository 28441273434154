import React from "react";
import { MDBFormInline, MDBIcon } from "mdbreact";

class SearchLocation extends React.Component{
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event, node) {
    // enable location search with the enter key
    event.preventDefault();

    var formNode = event.target
    // node is either "parent" or "child"
    if (node === "child") {
      formNode = event.target.parentNode;
    } 

    var value = formNode.childNodes[0].value;
    if (!value) {return}
    localStorage.setItem('userSearchLocation', value);
    this.props.updateLocation();
    this.props.updateFilters("location", "");
  }

  render() {
    if (this.props.source === "Map") {
      // load from Map.js
      return (
        <div className="form-group-wrapper">
          <h5>Location</h5>
          <MDBFormInline
            className="mt-0 mb-0 md-form"
            onSubmit={(e) => this.handleClick(e, "parent")}
          >
            <input
              className="form-control search"
              type="text"
              placeholder={this.props.selectedLocation || "Search"}
              aria-label="Search"
            />
            <MDBIcon 
              icon="search" 
              className="search-icon primary-text-color" 
              onClick={(e) => this.handleClick(e, "child")}
              type="submit"
            />
          </MDBFormInline>
        </div>
      );
    } else {
      // load from AlertList.js
      return (
        <div className="flex-grow-1 ml-32 input-group search-mobile">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-text1">
              <MDBIcon icon="search" />
            </span>
          </div>
          <form onSubmit={(e) => this.handleClick(e, "parent")}>
            <input
              className="form-control"
              type="text"
              placeholder={this.props.selectedLocation || "Search by location"}
              aria-label="Search"
            />
          </form>
        </div>
      )
    }
  }
}

export default SearchLocation;
