import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBBtn
} from "mdbreact";

class Navbar extends Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <MDBNavbar color="white" expand="md">
          <MDBNavbarBrand>
            <strong className=""><a href="/">DrugMap</a></strong>
          </MDBNavbarBrand>
	    <span className="navbar-toggler"><FontAwesomeIcon onClick={this.toggleCollapse} icon={['far', 'bars']} /></span>
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left right>
              <MDBNavItem active>
                <MDBNavLink to="/map">Map</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/about">About</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/charts">Charts</MDBNavLink>
              </MDBNavItem>
	    { /*<MDBNavItem>
                <MDBNavLink to="#!">Research</MDBNavLink>
              </MDBNavItem>*/}
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <a href="mailto:info@healthmap.org">
                  <MDBBtn rounded outline color="primary">
                    Contact
                  </MDBBtn>
                </a>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default Navbar;
