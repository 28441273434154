import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBBadge } from "mdbreact";

class AlertListItem extends Component {
  render() {
    let alert = this.props.alertItem;

    if(alert.state_name && alert.state_name !== 'null' && alert.state_name !== ''){
      var outputPlace = alert.place_name + ", " + alert.state_name;
    } else {
      var outputPlace = alert.place_name ;
    }
    return (
      <a className="alert-item" href={alert.url} target="_blank">
        <span className="mb-1 d-block overflow-auto small">
          <span className="float-left light">
            <FontAwesomeIcon icon="map-marker-alt" />
            <span className="pl-2">{ outputPlace }</span>
          </span>
          <h5 className="mb-0 float-right">{alert.pub_date}</h5>
        </span>
        <div className="d-flex">
          <span className="d-flex mr-auto flex-column">
            <p className="alert-title mb-2">{alert.title}</p>
            <p className="mb-0">
              {alert.products
                ? alert.products.map((drug, i) => {
                    return (
                      <MDBBadge className="tag-drug tag" key={i}>
                        {drug.name}
                      </MDBBadge>
                    );
                  })
                : null}
              {alert.categories
                ? alert.categories.map((category, i) => {
                    return (
                      <MDBBadge className="tag-category tag" key={i}>
                        {category.name}
                      </MDBBadge>
                    );
                  })
                : null}
            </p>
          </span>
          <span className="d-flex ml-3 align-self-center mt-n4">
            <FontAwesomeIcon
              icon={["far", "external-link-square-alt"]}
              className="light arrow-icon"
            />
          </span>
        </div>
      </a>
    );
  }
}

export default AlertListItem;
