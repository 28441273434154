import React from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Navbar from "../components/NavBar";
import SearchLocation from "../components/SearchLocation";
import SelectSearchDrugs from "../components/SelectSearchDrugs";
import SelectCategory from "../components/SelectCategory";
import SelectTimeFrame from "../components/SelectTimeFrame";
// import SelectSortBy from "../components/SelectSortBy";
// import SelectSource from "../components/SelectSource";
import SearchKeyword from "../components/SearchKeyword";
import AlertList from "../components/AlertList";
import Map from "../components/Map";
import MapFooter from "../components/MapFooter";
import * as GeoLocationInfo from '../actions/GeoLocation-action';

import PulseLoader from 'react-spinners/PulseLoader';

import { updateAlertsHelper, defaultFilters } from "../helpers/FilterHelpers"
import { dateReformatter } from "../helpers/Utils"
import { getReportByCountryAPI } from "../api/ReportAPI"

const override = 'display: block; margin-left: 200px;margin-top:150px; border-color: red;'

var _ = require('lodash');

class MapPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPopup: false,
      retrivedCount: '',
      currentUserLat: 36.8465,
      currentUserLon: -100,
      loadingIndicator: true,
      mapPageAlerts: '',
      filteredAlerts: null,
      selectedLat: 0,
      selectedLon: 0,
      selectedLocation: localStorage.getItem('userSearchLocation')
    };

    // set default filters
    this.appliedFilters = defaultFilters;

    // add an reference for parent to update child components
    this.filteredAlertList = React.createRef();

    this.updateLatLonValues = this.updateLatLonValues.bind(this)
    this.getMyLocation = this.getMyLocation.bind(this)
    this.updateFilters = this.updateFilters.bind(this);
    this.updateLocation = this.updateLocation.bind(this);
  }

  updateLocation() {
    var userSelectedLocation = localStorage.getItem('userSearchLocation')
    
    // Default the MAP to United States when there is no user selected location entered
    if(!userSelectedLocation || userSelectedLocation == '' || userSelectedLocation == null || userSelectedLocation == 'null'){
      userSelectedLocation = 'United States';
    }

    if (!this.state.loadingIndicator || this.state.selectedLocation !== userSelectedLocation) {
      this.setState({
        loadingIndicator: true,
        selectedLocation: userSelectedLocation
      })
    }
    
    // geocode user selected location
    this.props.actions.getGeoLocationInfo(userSelectedLocation).then((loc) => {
      
      if(loc !== "Error" && loc.features && loc.features.length > 0){

        var lat = loc.features[0].geometry.coordinates[1];
        var lon = loc.features[0].geometry.coordinates[0];

        console.log("Lat -> ", lat ," and Lon -> ", lon);

        this.updateLatLonValues(lat, lon);
  
        var reportAPIFunction = null;
        if (userSelectedLocation === "United States") {
          // get all alerts by country
          reportAPIFunction = getReportByCountryAPI();
        } else {
          // get alerts based on the user selected location
          reportAPIFunction = this.props.actions.getMapPageDrugAlerts(lat, lon);
        }
  
        reportAPIFunction.then((mapAlerts) => {
          if (mapAlerts.data) {
            mapAlerts.data.forEach((alert, i) => {
              alert.pub_date = dateReformatter(alert.pub_date);
            });
          }
          let alerts = updateAlertsHelper(
            mapAlerts,
            this.appliedFilters
          )
          this.setState({
            filteredAlerts: alerts,
            mapPageAlerts: mapAlerts,
            loadingIndicator: false,
            selectedLat: lat,
            selectedLon: lon
          })
        })

      } else {
        let lat = 0;
        let lon = 0;
        this.setState({
          filteredAlerts: {"data": []},
          mapPageAlerts: {"data": []},
          loadingIndicator: false,
          selectedLat: lat,
          selectedLon: lon
        })
      }
    })
  }

  componentDidMount() {
    this.updateLocation();

    // var currentGeoLat = localStorage.getItem('CurrentUserLat')
    // var currentGeoLon = localStorage.getItem('CurrentUserLon')

    // if (currentGeoLat != '' && currentGeoLon != '') {
    //   console.log("Props action -> ", this.props.actions)
    //   this.props.actions.getMapPageDrugAlerts(currentGeoLat, currentGeoLon).then((mapAlerts) => {
    //     console.log("mapAlerts ---------->", mapAlerts)
    //     this.setState(prevState => ({
    //       mapPageAlerts: mapAlerts,
    //       loadingIndicator: false
    //     }))
    //   })
    // } else {

    // }
  }

  getMyLocation() {
    return new Promise(function (resolve, reject) {
      const location = window.navigator && window.navigator.geolocation
      if (location) {
        location.getCurrentPosition((position) => {
          localStorage.setItem('CurrentUserLat', position.coords.latitude);
          localStorage.setItem('CurrentUserLon', position.coords.longitude);
          resolve(true)
        }, (error) => {
          localStorage.setItem('CurrentUserLat', this.state.selectedLat);
          localStorage.setItem('CurrentUserLon', this.state.selectedLon);
          reject(error)
        })
      }
    });
  }

  updateLatLonValues(lat, lon) {
    this.setState(prevState => ({
      currentUserLat: lat,
      currentUserLon: lon,
      // loadingIndicator: false
    }))
  }

  updateFilters(filterName, filterOptions) {
    this.appliedFilters[filterName] = filterOptions;
    var alerts = updateAlertsHelper(
      this.state.mapPageAlerts,
      this.appliedFilters
    )
    this.setState({filteredAlerts: alerts})

    // update child components
    if (this.filteredAlertList.current) {
      this.filteredAlertList.current.state.filteredAlerts = alerts;
    }
  }

  static getDerivedStateFromProps(props, state) {
    let statesToUpdate = {};
    if (props.locationInfo && props.locationInfo.features && props.locationInfo.features.length > 0) {
      let coords = props.locationInfo.features[0].geometry.coordinates;
      statesToUpdate["selectedLat"] = coords[1];
      statesToUpdate["selectedLon"] = coords[0];
    }
    return statesToUpdate
  }

  render() {
    let mapAlertData = null;
    if (this.state.mapPageAlerts && this.state.mapPageAlerts.data) {
      mapAlertData = this.state.mapPageAlerts;
    }

    // if(!_.isEmpty(this.state.retrivedCount)){
    //   var recordCounts = this.props.
    // }

    return (
      <div>
        <Navbar />
        <MDBContainer fluid className="overflow-hidden">
          <MDBRow>
            <MDBCol md="" className="filter-panel">
              <SearchLocation
                updateFilters={this.updateFilters}
                selectedLocation={this.state.selectedLocation}
                updateLocation={this.updateLocation}
                source="Map"
              />
              <SelectCategory updateFilters={this.updateFilters} />
              <SelectSearchDrugs updateFilters={this.updateFilters} />
              <SelectTimeFrame updateFilters={this.updateFilters} />
	    {/*<SelectSource />
              <SelectSortBy />*/}
              <SearchKeyword updateFilters={this.updateFilters} />
            </MDBCol>
            <MDBCol md="" className="list-container">
              {
                mapAlertData
                ? <AlertList
                    userLat={this.state.selectedLat}
                    userLon={this.state.selectedLon}
                    selectedLocation={this.state.selectedLocation}
                    alertData={mapAlertData}
                    filteredAlerts={this.state.filteredAlerts || mapAlertData}
                    appliedFilters={this.appliedFilters}
                    updateLocation={this.updateLocation}
                    ref={this.filteredAlertList}
                  />
                : <PulseLoader
                    css="text-align:center;margin-top: 6rem;"
                    sizeUnit={"px"}
                    size={30}
                    color={'#29b4cc'}
                    loading={this.state.loadingIndicator}
                  />
              }

            </MDBCol>
            <MDBCol md="" className="map-container pl-0 pr-0">
              {
                this.state.selectedLat && this.state.selectedLon
                ? <Map
                    userLat={this.state.selectedLat}
                    userLon={this.state.selectedLon}
                    selectedLocation={this.state.selectedLocation}
                    filteredAlerts={this.state.filteredAlerts || mapAlertData}
                  />
                : <PulseLoader
                    css="text-align:center;margin-top: 6rem;"
                    sizeUnit={"px"}
                    size={30}
                    color={'#29b4cc'}
                    loading={this.state.loadingIndicator}
                  />
              }

            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MapFooter />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    locationInfo: state.locationState.locationInfo,
    mapPageAlerts: state.mappagealertstate.mapPageAlerts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(GeoLocationInfo, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MapPage));

// export default MapPage;
