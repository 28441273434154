import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Navbar from "../components/NavBar";
import LogoBar from "../components/LogoBar";
import Footer from "../components/Footer";

class TermsPage extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <div className="wrapper light-gray pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol sm="12" md="8" lg="7" className="pt-md-80 pb-md-80">
                <h1 className="mb-5">Terms of Use</h1>
                <p className="large">
                  The views and opinions expressed in social media content are
                  those of the posting account holder and do not reflect the
                  opinions of DrugMap.
                </p>
                <p className="large">
                  By using this site you signify that you understand and agree
                  to&nbsp;
                  <a
                    className="normal"
                    href="http://www.diseasedaily.org/about/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HealthMap's Terms of Use
                  </a>
                  .
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        <LogoBar />
        <Footer />
      </div>
    );
  }
}

export default TermsPage;
