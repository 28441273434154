import axios from "axios";

export function getReportAPI(report_id) {
  const path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/?report_id=" + report_id;
  return axios.get(path).then(resp => {
    return resp.data;
  }).then((report) => {
    return report.data[0]
  })
}

export function getReportByCountryAPI(country_code="US", time_frame="", category="") {
  let path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/?country_code=" + country_code;
  if (time_frame) {
    path = path + "&time_frame=" + time_frame;
  }
  if (category) {
    path = path + "&category=" + category;
  }
  return axios.get(path).then(resp => {
    return resp.data;
  }).then((report) => {
    return report
  })
}

export function getCDCReportsAPI() {
  const path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/?cdc=1";
  return axios.get(path).then(resp => {
    return resp.data;
  }).then((report) => {
    return report
  })
}

export function getReportsMetaDataAPI() {
  const path = process.env.REACT_APP_DRUGMAP_SERVICES_BASE_URL + "reports/meta/";
  return axios.get(path).then(resp => {
    return resp.data;
  }).then((data) => {
    return data
  })
}
