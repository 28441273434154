import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";

import bchlogo from "../assets/img/svg/logos/bch.svg";
import hmslogo from "../assets/img/svg/logos/hms.svg";
import hmlogo from "../assets/img/svg/logos/hm.svg";

const LogoBar = () => {
  return (
    <div className="wrapper">
      <MDBContainer>
        <MDBRow className="text-center pt-96 pb-96">
          <MDBCol md="4" sm="12">
            <img src={bchlogo} className="logo-list" alt="logo" />
          </MDBCol>
          <MDBCol className="mt-md-0 mb-md-0 mt-3 mb-3" md="4" sm="12">
            <img src={hmlogo} className="logo-list hm" alt="logo" />
          </MDBCol>
          <MDBCol md="4" sm="12">
            <img src={hmslogo} className="logo-list" alt="logo" />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default LogoBar;
