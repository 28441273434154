import React from "react";
import { MDBFormInline, MDBIcon } from "mdbreact";
import { disableEnter } from '../helpers/FilterHelpers'


class SearchKeyword extends React.Component{
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    // add filter
    this.props.updateFilters("keyword", event.target.value);
  }

  render() {
    return (
      <div className="form-group-wrapper">
        <h5>Keyword</h5>
        <MDBFormInline
          className="mt-0 mb-0 md-form"
          onKeyPress={disableEnter}
        >
          <input
            className="form-control search"
            type="text"
            placeholder="Search"
            aria-label="Search"
            onChange={this.handleChange}
          />
          <MDBIcon icon="search" className="search-icon primary-text-color" />
        </MDBFormInline>
      </div>
    );
  }
}

export default SearchKeyword;
