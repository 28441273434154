// import { createStore, applyMiddleware } from 'redux';
// import reducers from './reducers/rootReducer';
// // import thunk from 'redux-thunk';

// function setupStore() {
//     return createStore(
//         reducers
//     );
// }

// const store = setupStore();

// export default function configureStore() {
//     return store;
// }

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import initialState from './InitialState'
export default function configureStore(initialState) {
    return createStore(
        rootReducer,
        initialState,
        applyMiddleware(thunk)
    );
}
