import * as ActionTypes from "./action_types";
import * as GeoLocationAPI from "../api/GeoLocationApi";

export const getGeoLocationInfo = (zipcode) => {
  return function (dispatch) {
    return GeoLocationAPI.getGeoLocation(zipcode)
      .then(locationInfo => {
        dispatch({ type: ActionTypes.SET_USER_SEARCH_LOCATION, locationInfo });
        return locationInfo;
      })
      .catch(error => {
        // throw error;
        dispatch({ type: ActionTypes.SET_USER_SEARCH_LOCATION, error });
        return "Error";
      });
  };
}


export const getHomePageDrugAlerts = (lat, lon) => {
  return function (dispatch) {
    return GeoLocationAPI.getHomePageAlerts(lat, lon)
      .then(homePageAlerts => {
        dispatch({ type: ActionTypes.GET_HOMEPAGE_DRUG_ALERTS, homePageAlerts });
        return homePageAlerts;
      })
      .catch(error => {
        throw error;
      });
  };
}

export const getMapPageDrugAlerts = (lat, lon) => {
  return function (dispatch) {
    return GeoLocationAPI.getMapPageAlerts(lat, lon)
      .then(mapPageAlerts => {
        dispatch({ type: ActionTypes.GET_MAPPAGE_DRUG_ALERTS, mapPageAlerts });
        return mapPageAlerts;
      })
      .catch(error => {
        throw error;
      });
  };
}
