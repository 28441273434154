import * as types from "../actions/action_types";
import initialState from "../InitialState";

const GeoLocationReducer = (state = initialState, action) => {
    console.log("Inside GeoLocation-Reducer File ")
    switch (action.type) {
        case types.SET_USER_SEARCH_LOCATION:
            console.log("Current case action => ", action.locationInfo)
            // return { ...state, locationInfo: action.locationInfo };
            return Object.assign({}, state, {
                locationInfo: action.locationInfo
            });
        default:
            return state
    }
};
export default GeoLocationReducer;
