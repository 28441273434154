import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";

import informsvg from "../assets/img/svg/inform-decisions.svg";
import locationsvg from "../assets/img/svg/location.svg";
import learningsvg from "../assets/img/svg/machine-learning.svg";

class AboutPage extends React.Component {
  render() {
    return (
      <div className="large-gutter">
        <Navbar />
        <div className="d-flex align-items-center about-banner-bg">
          <MDBContainer>
            <MDBRow className="">
              <MDBCol lg="8" md="8" sm="12">
                <h1 className="mb-0 normal-weight">
                  Our goal is to bring awareness to the opioid epidemic and to
                  understand community needs to generate public and political
                  action.
                </h1>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="5" className="pt-md-80 pb-md-80">
                <h5 className="mb-4">What is DrugMap?</h5>
                <p className="large">
                  DrugMap is a free, online platform that showcases the
                  geographic distribution of news reports about opioids and
                  provides a comprehensive overview of the current state of the
                  opioid epidemic.
                </p>
              </MDBCol>
              <MDBCol md="6" className="offset-md-1">
                <div className="map-bg" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper dark-white-tint pt-96 pb-96">
          <MDBContainer>
            <MDBRow className="pt-md-80 pb-md-80">
              <MDBCol lg="12">
                <h5 className="mb-4">How does DrugMap work?</h5>
              </MDBCol>
              <MDBCol lg="4" md="12">
                <img
                  src={learningsvg}
                  className="mb-3 about-icons"
                  alt="logo"
                />
                <h2 className="mb-3">Uses automated machine learning</h2>
                <p className="large">
                  Through an automated machine-learning process, the platform
                  displays news articles and social discussions about Opioid
                  use.
                </p>
              </MDBCol>
              <MDBCol lg="4" md="12">
                <img
                  src={locationsvg}
                  className="about-icons mb-3"
                  alt="logo"
                />
                <h2 className="mb-3">Geographic spread of opioid use</h2>
                <p className="large">
                  Search for news media about opioid use within a given
                  geographic area, across time and by opioid type.
                </p>
              </MDBCol>
              <MDBCol lg="4" md="12">
                <img src={informsvg} className="mb-3 about-icons" alt="logo" />
                <h2 className="mb-3">Informs public health decisions</h2>
                <p className="large">
                  DrugMap is used by clinicians, the public, and policymakers to
                  inform decisions about care and prevention.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="6" className="">
                <div className="map-twitter-bg" />
              </MDBCol>
              <MDBCol md="5" className="offset-md-1 pt-md-80 pb-md-80">
                <h5 className="mb-4">What kind of data do we show?</h5>
                <p className="large">
                  DrugMap shows news articles and social discussions about
                  opioids. We believe these posts are important to report in
                  their unfiltered form to further identify potential public
                  health implications and to better understand how communities
                  are responding to the opioid crisis. Social media posts may
                  contain potentially offensive language and may not be suitable
                  for all audiences.
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="wrapper primary pt-96 pb-96">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg="10" md="12" className="pt-md-80 pb-md-80">
                <h5 className="mb-4">Who we are</h5>
                <h2 className="mb-3">
                  DrugMap is maintained by HealthMap, a team of researchers,
                  epidemiologists and software developers at Boston Children's
                  Hospital. Visit{" "}
                  <a
                    href="http://healthmap.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="normal">
                    healthmap.org
                  </a>{" "}
                  to learn more about HealthMap.
                </h2>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        <Footer />
      </div>
    );
  }
}

export default AboutPage;
