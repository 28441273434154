import { quantile } from "./Utils";

export function updatePercentiles(featureCollection, cdcData, cdcIndicator) {
  let allNumbers = [];
  let indicator = "";
  if (cdcIndicator === 1) {
    // use the predicted number
    allNumbers = [
      ...cdcData.map(function(f) {
        return Number(f.predicted_value);
      })
    ];
    indicator = "predicted";
  } else {
    // use the reported number
    allNumbers = [
      ...cdcData.map(function(f) {
        return Number(f.data_value);
      })
    ];
    indicator = "reported";
  }

  // let minPredicted = Math.min(...allNumbers);
  let firstQuantile = quantile(allNumbers, 0.25);
  let medianPredicted = quantile(allNumbers, 0.5);
  let thirdQuantile = quantile(allNumbers, 0.75);
  let maxPredicted = Math.max(...allNumbers);

  const { features } = featureCollection;
  features.forEach((f, i) => {
    let cdcReport = cdcData.filter(d => d.state_name === f.properties.name);
    if (cdcReport.length > 0) {
      f.properties.reported = cdcReport[0].data_value;
      f.properties.predicted = cdcReport[0].predicted_value;
      f.properties.category = cdcReport[0].category;
      f.properties.time_frame = cdcReport[0].time_frame;
      if (f.properties[indicator] === 0) {
        f.properties.percentile = 0;
      } else if (f.properties[indicator] <= firstQuantile) {
        f.properties.percentile = 1;
      } else if (f.properties[indicator] <= medianPredicted) {
        f.properties.percentile = 2;
      } else if (f.properties[indicator] <= thirdQuantile) {
        f.properties.percentile = 3;
      } else {
        f.properties.percentile = 4;
      }
    }
  });

  return {
    type: "FeatureCollection",
    max: maxPredicted,
    features: features.map(f => {
      const properties = {
        ...f.properties,
        indicator: indicator
      };
      return { ...f, properties };
    })
  };
}

export const dataLayer = {
  id: "data",
  type: "fill",
  paint: {
    "fill-outline-color": "#FFF",
    "fill-color": {
      property: "percentile",
      stops: [
        [0, "#FFF"],
        [1, "#FFE9A6"],
        [2, "#FAB17D"],
        [3, "#FE6D72"],
        [4, "#AD2B3C"]
      ],
      default: "#fff"
    },
    "fill-opacity": 0.9
  }
};
