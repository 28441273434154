import React, { Component } from "react";
import { MDBSelect, MDBSelectOption, MDBSelectOptions, MDBSelectInput } from "mdbreact";

class SelectSource extends Component {

  render() {
    return (
      <div className="multi-select-group-wrapper dropdown-pb-3 dropdown-pt-3">
        <h5>News Source</h5>
        <MDBSelect multiple={true}>
          <MDBSelectInput selected="Choose your option" />
          <MDBSelectOptions>
            <MDBSelectOption value="1" selected>
              News
            </MDBSelectOption>
            <MDBSelectOption value="2">Twitter</MDBSelectOption>
            <MDBSelectOption className="disabled-note" disabled>
              Twitter data may contain offensive language. By displaying twitter
              data you are agreeing to our Terms of Service.
            </MDBSelectOption>
          </MDBSelectOptions>
        </MDBSelect>
      </div>
    );
  }
}

export default SelectSource;
