import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <MDBFooter color="black" className="pb-4 pt-4 mt-0">
      <MDBContainer className="pb-4 light-gray-text">
        <MDBRow className="pt-4 pb-4">
          <MDBCol size="12" md="6">
          <p><FontAwesomeIcon icon={['far', 'copyright']} /><span className="pl-2">{new Date().getFullYear()} <a href="http://www.childrenshospital.org" rel="noopener noreferrer" target="_blank">Boston Children's Hospital</a></span></p>  
          </MDBCol>
          <MDBCol size="12" md="6">
            <ul className="mx-0 px-0 float-md-right">
              <li className="float-left mr-3 list-unstyled">
                <a href="/terms">Terms of Use</a>
              </li>
              <li className="float-left ml-3 list-unstyled">
                <a href="/privacy">Privacy</a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}

export default Footer;
