import React, { Component } from "react";
import { MDBBadge } from "mdbreact";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_PUBLIC_TOKEN;
const mapStyle = process.env.REACT_APP_MAPBOX_MAPSTYLE;

class Map extends Component {
  constructor(props) {
    super(props);

    this.markerReports = {};

    this.mapGL = React.createRef();

    this.locateUserSelectedLocation = this.locateUserSelectedLocation.bind(
      this
    );
    if (this.props.selectedLocation === "United States") {
      this.zoom = 3;
    } else {
      this.zoom = 8;
    }
    this.createMarkers = this.createMarkers.bind(this);

    this.state = {
      showPopup: false,
      popupCoord: [0, 0],
      markerNumber: -1,
      retrivedCount: 0,
      viewport: {
        width: "100%",
        height: "100%",
        latitude: this.props.userLat,
        longitude: this.props.userLon,
        zoom: this.zoom,
        bearing: 0,
        pitch: 0
      },
      selectedLocation: this.props.selectedLocation
    };
  }
  
  _onViewportChange(viewport) {
    this.setState({
      viewport: { ...this.state.viewport, ...viewport }
    });
  }

  componentDidUpdate(prevProps) {
    let selectedLocation = this.props.selectedLocation;
    let viewport = this.state.viewport;

    if (prevProps.userLat !== this.props.userLat) {

      viewport.zoom = selectedLocation === "United States" ? 3 : 8;
      viewport.latitude = this.props.userLat;
      viewport.longitude = this.props.userLon;
      this.setState({
        selectedLocation: selectedLocation,
        viewport: viewport
      });
    }
}

  componentDidMount() {
    this.setState(prevState => ({
      viewport: {
        // object that we want to update
        width: "100%",
        height: "100%",
        latitude: this.props.userLat,
        longitude: this.props.userLon,
        zoom: this.zoom // update the value of specific key
      }
    }));
    window.addEventListener("resize", this._resize);
    this._resize();
  }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this._resize);
  // }

  _resize = () => {
    this._onViewportChange({
      width: "100%",
      height: "100%"
    });
  };

  locateUserSelectedLocation() {
    return (
      <Marker
        latitude={this.props.userLat}
        longitude={this.props.userLon}
        offsetLeft={-20}
        offsetTop={-10}
      />
    );
  }

  _organizeMarkerData(data) {
    let geoData = {};
    let lat = 0;
    let lon = 0;
    let key = "";

    for (let i in data) {
      let d = data[i];
      lat = parseFloat(d.lat).toFixed(2);
      lon = parseFloat(d.lon).toFixed(2);
      key = lat + "," + lon;
      if (geoData[key]) {
        geoData[key].push(d);
      } else {
        geoData[key] = [d];
      }
    }
    return geoData;
  }

  createMarkers() {
    if (!this.props.filteredAlerts) return;

    let data = this._organizeMarkerData(this.props.filteredAlerts.data);
    let m = parseInt(this.state.markerNumber);

    let markerReports = {};
    let markers = [];
    let i = 0;
    let alerts = [];

    for (let key in data) {
      alerts = data[key];
      let totalAlerts = alerts.length;
      // let iconSize = (totalAlerts) < 10 ? "small" : "medium";
      totalAlerts = totalAlerts === 1 ? "" : totalAlerts;
      let iconSize = "";
      // let iconSize = (totalAlerts) < 2 ? "" : "small";
      if (totalAlerts < 2) {
        iconSize = "";
      }
      if (totalAlerts >= 2 && totalAlerts < 10) {
        iconSize = "small";
      }
      if (totalAlerts >= 10 && totalAlerts < 20) {
        iconSize = "medium";
      }
      if (totalAlerts >= 20) {
        iconSize = "large";
      }

      let latAndLon = key.split(",");
      let lat = parseFloat(latAndLon[0]);
      let lon = parseFloat(latAndLon[1]);

      markers.push(
        <Marker
          latitude={lat}
          longitude={lon}
          offsetLeft={-20}
          offsetTop={-10}
          key={i}
        >
          <div
            id={`marker-${i}`}
            className={`map-icon ${iconSize} ${m === i ? "active" : ""}`}
            data-mark={i}
            onClick={e => {
              let markID = e.target.attributes.getNamedItem("data-mark").value;
              const mapGL = this.mapGL.current.getMap();
              const bounds = mapGL.getBounds();
         //     const north = bounds._ne.lat;
          //    const south = bounds._sw.lat;
          //    const east = bounds._ne.lng;
          //    const west = bounds._sw.lng;
          //    const targetLat = north - (north - south) / 10;
              this.setState({
                showPopup: true,
                //popupCoord: [targetLat, bounds._sw.lng],
		popupCoord: [bounds._ne.lat, bounds._sw.lng],
                //popupCoord: [lat, lon],
                markerNumber: markID
              });
            }}
          >
            {totalAlerts}
          </div>
        </Marker>
      );
      markerReports[i] = alerts;
      i++;
    }

    this.markerReports = markerReports;
    return markers;
  }

  render() {
    const showPopup = this.state.showPopup;

    let localReports = {};
    let place = "";
    let drugs = new Set();
    let categories = new Set();
    if (showPopup && this.state.markerNumber) {
      localReports = this.markerReports[this.state.markerNumber];
      if (localReports) {

        // Display state name only if it exists
        if((localReports[0].state_name) && (localReports[0].state_name !== 'null') && (localReports[0].state_name !== '') ){
          place = localReports[0].place_name + ", " + localReports[0].state_name;
        } else {
          place = localReports[0].place_name ;
        }

        localReports.forEach(function(localReport) {
          if (localReport.products) {
            localReport.products
              .map(function(drug) {
                return drug.name;
              })
              .forEach(drugs.add, drugs);
          }
          if (localReport.categories) {
            localReport.categories
              .map(function(category) {
                return category.name;
              })
              .forEach(categories.add, categories);
          }
        });
      }
    }

    // update lat/lon
    var viewport = this.state.viewport;
    // viewport.latitude = this.props.userLat;
    // viewport.longitude = this.props.userLon;
    // viewport.zoom = this.props.selectedLocation === "United States" ? 3 : 8;

    return (
      <ReactMapGL
        onClick={() => this.setState({ showPopup: false, markerNumber: -1 })}
        mapStyle={mapStyle}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        {...viewport}
        onViewportChange={viewport => this._onViewportChange(viewport)}
        scrollZoom={true}
        ref={this.mapGL}
      >
        {this.locateUserSelectedLocation()}
        {this.createMarkers()}

        {showPopup && (
          <Popup
            latitude={this.state.popupCoord[0]}
            longitude={this.state.popupCoord[1]}
            closeButton={true}
            closeOnClick={false}
            onClose={() => this.setState({ showPopup: false })}
            tipSize={1}
            captureScroll={true}
            className="popup-container"
            anchor="top-left"
            dynamicPosition={false}
          >
		        <h2 className="popup-title">{place}</h2>
            <div className="popup">
		            {/*<p className="gray-text">
                <FontAwesomeIcon icon="map-marker-alt" />
                <span className="pl-2">{place}</span>
              </p>*/}
            <div className="scroll-content">
              <h3>Drugs and Categories</h3>
              <p className="mb-0">
                {drugs
                  ? [...drugs].map((drug, i) => {
                      return (
                        <MDBBadge className="tag-drug tag" key={i}>
                          {drug}
                        </MDBBadge>
                      );
                    })
                  : null}
                {categories
                  ? [...categories].map((category, i) => {
                      return (
                        <MDBBadge className="tag-category tag" key={i}>
                          {category}
                        </MDBBadge>
                      );
                    })
                  : null}
              </p>
              <h3 className="mt-3">Headlines</h3>
              {localReports.map((localReport, index) => {
                return (
                  <div key={index}>
                    <h5 className="small mb-0">{localReport.pub_date}</h5>
                    <p className="alert-title">
                      <a
                        href={localReport.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="pr-2">{localReport.title}</span>
                        <FontAwesomeIcon
                          icon={["far", "external-link-square-alt"]}
                        />
                      </a>
                    </p>
                  </div>
                );
              })}
		{/*<br />*/}
            </div>
            </div>
          </Popup>
        )}
      </ReactMapGL>
    );
  }
}

export default Map;