import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { MDBSelect } from "mdbreact";

import { getAllCategories } from '../helpers/LocalStorageHelpers'


class SelectCategory extends Component {
  constructor(props) {
    super(props);

    // get all categories from local storage or API
    this.categories = getAllCategories() || [];

    this.state = {
      options: this.categories
    }

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.options) {
      this.setState({options: this.categories})
    }
  }

  handleChange(event) {
    // get selected time frame
    var selectedCategories = [];
    if (this.state.options) {
      this.state.options.forEach(function(option) {
        if (option.checked) {
          selectedCategories.push(option.value);
        }
      })
    }

    // add filter
    this.props.updateFilters("categories", selectedCategories);
  }

  render() {
    // if no categories returned, redirect to the landing page
    // if (this.categories.length === 0) {
    //   return(
    //     <Redirect push to="/" />
    //   );
    // }

    return (
      <div className="multi-select-group-wrapper">
        <h5>Category</h5>
        <MDBSelect
          className="dropdown-pb-3"
          multiple
          search
          searchId="selectCategory"
          options={this.state.options}
          selected="Choose category"
          selectAll
          getValue={this.handleChange}
        />
      </div>
    );
  }
}

export default SelectCategory;
